import { template as template_883ee8c568ac47eb93e3d34c75082907 } from "@ember/template-compiler";
import { eq } from "truth-helpers";
const FKControlConditionalContentItem = template_883ee8c568ac47eb93e3d34c75082907(`
  {{#if (eq @name @activeName)}}
    <div class="form-kit__conditional-display-content">
      {{yield}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlConditionalContentItem;
