import { template as template_c29ed285cc9740e7abb3570ffc7e53ea } from "@ember/template-compiler";
const FKControlMenuContainer = template_c29ed285cc9740e7abb3570ffc7e53ea(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
