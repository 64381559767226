import { template as template_90a443bfe47844519362e1e8aeb66751 } from "@ember/template-compiler";
const FKLabel = template_90a443bfe47844519362e1e8aeb66751(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
