import { template as template_b8398df53c86490997d1b986d61b04ae } from "@ember/template-compiler";
const WelcomeHeader = template_b8398df53c86490997d1b986d61b04ae(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
